@import 'variables';
.animated-block {
  padding: 18px;
  border: 2px solid #2F3676;
  background-color: white;
  border-radius: 5px;
  transition: background-color 0.25s;
  z-index: 1;
  cursor: pointer;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $gradientColor;
    z-index: -1;
    transition: opacity 0.25s linear;
    opacity: 0;
    border-radius: 5px; }

  &:hover {
    border: 0;
    border: 2px solid white;
    padding: 18px;
    color: white;

    &::before {
      opacity: 1; } } }

.checked-block {
  border: 0;
  padding: 20px;
  color: white;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $gradientColor;
    z-index: -1;
    transition: opacity 0.25s linear;
    border-radius: 5px;
    opacity: 1; } }
