@font-face {
    font-family: 'Suisse Intl';
    src: url('fonts/SuisseIntl-Regular.woff') format('woff'), url('fonts/SuisseIntl-Regular.ttf') format('truetype');
}

body {
    font-family: 'Suisse Intl', sans-serif;
    font-size: 16px;
    letter-spacing: -0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: -0.03em;
    line-height: 1.4 !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal-filters-txt,
.modal-fields-txt {
    word-break: break-word;
}

.checkout-confirmation > .button-wrapper {
    width: 100%;
    text-align: right;
}
