.quide-animated-block {
  height: 500px;
  overflow-y: auto;
  cursor: auto;

  .guide-content-container {
    img {
      width: calc(100% - 20px);
      margin: 10px;
      border-radius: 5px; }

    .content-block {
      padding-bottom: 20px; } }

  &:hover {
    border: 2px solid #2F3676;
    color: black; }

  &::before {
    position: relative; } }
