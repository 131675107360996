$mainColor: #2F3676;
$darkMainColor: #005662;
$lightMainColor: #5762c2;
$secondaryColor: #fca333;
$grayColor: #fafafa;
$gradientColor: linear-gradient(to bottom, #2F3676, #5762c2);
// sizing
$mobile-width: 320px;
$tablet-width: 768px;
$small-tablet-width: 500px;
$sm-material: 600px;
$textNoAthurization-fixed-width: 960px;
$desktop-width: 1280px;

// border
$bodderData: 2px dashed lightgrey;


// landing sizing

$landing-desktop-size: 1024px;
$landing-tablet-size: 768px;
$landing-mobile-size: 320px;
