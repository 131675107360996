@import 'variables';
$padding: 20px 40px;
$margin-top: 160px;
.page-frame-account-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  background: $mainColor;
  color: white;

  .logo {
    width: 100px;
    height: 100px;
    fill: white;
    position: absolute;
    cursor: pointer;
    top: 0;
    // right: 0
    left: 20px; }
  // margin: auto
  .light-text {
    font-weight: 400; }

  .buttons-back-container {
    position: absolute;
    bottom: 10px;
    left: 10px; }

  @media (max-width: $textNoAthurization-fixed-width) {
    padding-top: 100px;
    overflow-y: auto;
    align-items: baseline !important;
    .logo {
      left: 0;
      right: 0;
      margin: auto;
      @media (max-width: $small-tablet-width) {
        right: 10px;
        left: auto; } }
    .buttons-back-container {
      bottom: auto;
      top: 25px; } }

  .show-label-in-tablet-size {
    display: none;
    @media (max-width: $textNoAthurization-fixed-width) {
      display: block; } }

  .children-container {
    margin: $padding;
    margin-top: 0;
    width: 400px;
    // position: relative
    top: 5%;
    padding-right: 10px;
    @media (min-width: $desktop-width) {
      width: 590px; }
    @media (min-width: $textNoAthurization-fixed-width) {
      width: 440px; }
    @media (max-width: $textNoAthurization-fixed-width) {
      height: auto;
      margin: $padding;
      margin-top: 20px;
      width: 390px;
      top: auto; } }

  .fixed-element {
    max-width: 400px;
    @media (min-width: $desktop-width) {
      width: 600px; }
    @media (min-width: $textNoAthurization-fixed-width) {
      width: 450px; }
    @media (max-width: $textNoAthurization-fixed-width) {
      display: none; } }

  .padding-large-top {
    height: auto;
    top: 0;
    @media (max-width: $textNoAthurization-fixed-width) {
      margin-top: 20%; } } }
