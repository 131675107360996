@import 'variables';

.offer-container {
  .logo {
    width: 100px;
    height: 100px;

    .stLogoSvg0 {
      fill: $mainColor; } }

  .offer-block {
    border-top: $bodderData;
    margin: 20px 0;
    padding: 20px;

    .information-form-block {
      max-width: 400px;
      margin: auto; } }

  .price-block {
    border: 0;
    background: $gradientColor;
    color: white;
    border-radius: 5px;

    .typography-title {
      margin: 5px 0; } }

  .typography-bold {
    font-weight: bold; } }

.selection-box {
  display: flex;
  flex-wrap: wrap;

  > div {
    max-width: 120px; }

  .selection-items {
    display: flex;
    flex-wrap: wrap;

    span {
      padding-right: 5px; } } }
