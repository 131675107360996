$cardPadding: 12px;
.MyCardElement {
  padding: $cardPadding;
  width: calc(100% - #{$cardPadding*2});
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;

  &--focus {
    box-shadow: 0 1px 3px 0 #cfd7df; }

  &--invalid {
    border-color: #fa755a; }

  &--webkit-autofill {
    background-color: #fefde5 !important; } }
