@import 'variables';
.box-loader {
  display: flex;
  position: fixed;
  z-index: 3000;
  background: $mainColor;
  font-size: 30px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .loader {
    background: $secondaryColor;
    position: relative;
    animation: loader 1s ease-in-out infinite;
    animation-delay: 0.4s;
    width: .5em;
    height: 1.5em;
    margin: auto;

    &:after,
    &:before {
      content: '';
      position: absolute;
      width: inherit;
      height: inherit;
      background: inherit;
      animation: inherit; }

    &:before {
      right: .75em;
      animation-delay: 0.2s; }

    &:after {
      left: .75em;
      animation-delay: 0.6s; }

    @keyframes loader {
      0%,
      100% {
        box-shadow: 0 0 0 $secondaryColor, 0 0 0 $secondaryColor; }

      50% {
        box-shadow: 0 -.25em 0 $secondaryColor, 0 .25em 0 $secondaryColor; } } } }
